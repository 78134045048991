/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';

import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { hasRoom } from '../../core/helpers';
import './CustomDropdown.css';

interface IProps {
  children: JSX.Element;
  selected: string;
  items: any;
  handleChange: any;
  isLink?: boolean;
}

function CustomDropdown({ children, selected, items, handleChange, isLink }: IProps) {
  const { isMobile } = useScreenSizeContext();
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef(null);
  const itemsRef = useRef<HTMLLIElement[] | null[]>([]);
  const currentFocusIndex = useRef(0);

  const closeDropdownListener = useCallback(
    (e: any) => {
      if (btnRef.current !== e.target) {
        setIsOpen(false);
        document.removeEventListener('click', closeDropdownListener);
      }
    },
    [btnRef],
  );

  const closeDropdown = useCallback(
    (focus = true) => {
      setIsOpen(false);
      if (btnRef.current && focus) btnRef.current.focus();
      document.removeEventListener('click', closeDropdownListener);
    },
    [closeDropdownListener],
  );

  const moveFocus = useCallback((index: any) => {
    currentFocusIndex.current = index;
    itemsRef.current[index]?.focus();
  }, []);

  useEffect(() => {
    if (isOpen && !isLink) moveFocus(0);
  }, [isLink, isOpen, moveFocus]);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (isOpen) {
        closeDropdown();
      } else {
        e.preventDefault();
        setIsOpen(!isOpen);
      }
      document.addEventListener('click', closeDropdownListener);
    },
    [closeDropdown, closeDropdownListener, isOpen],
  );

  const handlePointerEnter = useCallback(
    (e: PointerEvent) => {
      if (!isLink || e.pointerType === 'touch') return;
      setIsOpen(true);
      document.addEventListener('click', closeDropdownListener);
    },
    [closeDropdownListener, isLink],
  );

  const handleMouseLeave = useCallback(() => {
    if (!isLink) return;
    closeDropdown(false);
  }, [closeDropdown, isLink]);

  const handleBtnKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (isLink && isOpen && !e.shiftKey && e.keyCode === 9) {
        e.preventDefault();
        moveFocus(0);
      } else if (e.keyCode === 9 || (e.shiftKey && e.keyCode === 9)) {
        closeDropdown();
      }
    },
    [closeDropdown, isLink, isOpen, moveFocus],
  );

  const handleFocus = useCallback(() => {
    if (!isOpen && isLink) {
      setTimeout(() => {
        setIsOpen(true);
        document.addEventListener('click', closeDropdownListener);
      }, 0);
    }
  }, [closeDropdownListener, isLink, isOpen]);

  const handleItemKeyDown = useCallback(
    (e: KeyboardEvent<HTMLLIElement>, value: any) => {
      if (e.keyCode === 13 || e.keyCode === 32) {
        e.preventDefault();
        if (handleChange) handleChange(e, value);
        closeDropdown();
      }

      if (e.keyCode === 38) {
        e.preventDefault();
        if (currentFocusIndex.current > 0) moveFocus(currentFocusIndex.current - 1);
        else moveFocus(itemsRef.current.length - 1);
      }

      if (e.keyCode === 40) {
        e.preventDefault();
        if (currentFocusIndex.current < itemsRef.current.length - 1)
          moveFocus(currentFocusIndex.current + 1);
        else moveFocus(0);
      }

      if (e.keyCode === 9 || (e.shiftKey && e.keyCode === 9)) {
        closeDropdown();
      }
    },
    [handleChange, closeDropdown, moveFocus],
  );

  if (!items) return null;

  return (
    <div className="custom-dropdown" onMouseLeave={handleMouseLeave}>
      <children.type
        {...children.props}
        className={`${children.props.className} custom-dropdown__btn${isOpen ? ' open' : ''}`}
        aria-haspopup="true"
        aria-expanded={isOpen}
        onPointerEnter={handlePointerEnter}
        onClick={handleClick}
        onKeyDown={handleBtnKeyDown}
        onFocus={handleFocus}
        ref={btnRef}
      />
      <ul
        ref={menuRef}
        className={`custom-dropdown__menu${isOpen ? ' custom-dropdown__menu--visible' : ''}`}
        role="menu"
        style={
          hasRoom(btnRef.current, menuRef.current)
            ? { left: 0 }
            : isMobile
            ? { left: '50%', transform: 'translateX(-50%)' }
            : { right: 0 }
        }
      >
        {items.map((item: any, i: any) => (
          <li
            className={`custom-dropdown__item${
              item.value === selected ? ' custom-dropdown__item--active' : ''
            }`}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            role="menuitem"
            tabIndex={-1}
            ref={(ref) => {
              itemsRef.current[i] = ref;
            }}
            onClick={
              handleChange
                ? (e) => {
                    handleChange(e, item.value);
                    if (!isLink) {
                      setTimeout(() => {
                        if (btnRef.current) btnRef.current.focus();
                      }, 0);
                    } else {
                      closeDropdown();
                    }
                  }
                : () => {}
            }
            onKeyDown={(e) => handleItemKeyDown(e, item.value)}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CustomDropdown;
