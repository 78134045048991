import { forwardRef } from 'react';

interface IProps {
  type: 'submit' | 'button';
  className: string;
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?(): void;
  onPointerEnter?(): void;
  onKeyDown?(): void;
  onFocus?(): void;
  id?: string;
}

const CustomButton = forwardRef<HTMLButtonElement, IProps>(
  (
    {
      type,
      loading,
      disabled,
      className,
      children,
      onClick,
      onPointerEnter,
      onKeyDown,
      onFocus,
      id,
    },
    ref,
  ) => (
    <button
      className={className}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={onClick}
      onPointerEnter={onPointerEnter}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      disabled={loading || disabled}
      ref={ref}
      id={id}
    >
      {children}
      {loading && <span className="loader">Loading...</span>}
    </button>
  ),
);

export default CustomButton;
