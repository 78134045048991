import { Route, Routes } from 'react-router-dom';

import App from './App';
import Legal from './Components/Legal/Legal';
import ProtectedRoute from './Components/ProtectedRoute';
import LandingApp from './LandingApp';
import AboutUs from './Pages/AboutUs/AboutUs';
import Article from './Pages/Article/Article';
import Blog from './Pages/Blog/Blog';
import Case from './Pages/Case/Case';
import CommunityPage from './Pages/Community/CommunityPage';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Home from './Pages/Home/Home';
import Invite from './Pages/Invite/Invite';
import LandingPage from './Pages/LandingPage/LandingPage';
import Page from './Pages/Page/Page';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import People from './Pages/People/People';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import AccountEdit from './Pages/User/Edit/Edit';
import EmailConfirm from './Pages/User/EmailConfirm/EmailConfirm';
import Login from './Pages/User/Login/Login';
import Overview from './Pages/User/Overview/Overview';
import SecurityPrivacy from './Pages/User/SecurityPrivacy/SecurityPrivacy';
import { useUserContext } from './core/context/user.context';
import { ToBool } from './core/helpers';

function AppRoutes() {
  const { user } = useUserContext();
  return (
    <Routes>
      {ToBool(process.env.REACT_APP_SHOW_LANDING_PAGE) ? (
        <Route path="/" element={<LandingApp />}>
          <Route index element={<LandingPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      ) : (
        <>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Invite />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="email-confirm/:hash" element={<EmailConfirm />} />
            <Route path="resetpassword" element={<ResetPassword />} />
            <Route element={<ProtectedRoute user={user} />}>
              <Route path="user">
                <Route index element={<Overview />} />
              </Route>
              <Route path="profile-edit" element={<AccountEdit />} />
              <Route path="security-privacy" element={<SecurityPrivacy />} />
            </Route>
            <Route path="blog">
              <Route index element={<Blog />} />
              <Route path=":slug" element={<Article />} />
            </Route>
            <Route path="case">
              <Route path=":slug" element={<Case />} />
            </Route>
            <Route path="about-us" element={<AboutUs />} />
            <Route path="community">
              <Route index element={<CommunityPage />} />
              <Route path="people/:slug">
                <Route index element={<People />} />
              </Route>
            </Route>
            <Route path="terms-and-conditions" element={<Legal type="termsAndConditions" />} />
            <Route path="privacy-policy" element={<Legal type="privacyPolicy" />} />
            <Route path=":slug" element={<Page />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
          <Route path="landing" element={<LandingApp />}>
            <Route index element={<LandingPage />} />
          </Route>
        </>
      )}
    </Routes>
  );
}

export default AppRoutes;
