/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable @typescript-eslint/no-explicit-any */
import ArticleShowcase from '../Articles/ArticleShowcase/ArticleShowcase';
import CasesShowcase from '../CasesShowcase/CasesShowcase';
import Contact from '../Contact/Contact';
import FAQ from '../FAQ/FAQ';
import GPT2 from '../GPT2/GPT2';
import GPT from '../GPT/GPT';
import GPTCarousel from '../GPTCarousel/GPTCarousel';
import ImageSection from '../ImageSection/ImageSection';
import Introduction from '../Introduction/Introduction';
import Markdown from '../Markdown/Markdown';
import PartnersSlider from '../PartnersSlider/PartnersSlider';
import PeopleShowcase from '../PeopleShowcase/PeopleShowcase';
import Programs from '../Programs/Programs';
import Promo from '../Promo/Promo';
import QuoteSlider from '../QuoteSlider/QuoteSlider';
import Result from '../Result/Result';
import { SimpleSlider } from '../SimpleSlider/SimpleSlider';
import Title from '../Title/Title';

interface IProps {
  data: any;
  childrendRef?: any;
  slug: string;
}

function PageBuilder({ data, slug }: IProps) {
  return data.map((component: any, i: number) => {
    if (component.hidden) return null;
    switch (component.component) {
      case 'introduction':
        return <Introduction key={i} {...component} />;
      case 'gpt':
        return <GPT key={i} {...component} />;
      case 'gpt2':
        return <GPT2 key={i} slug={slug} {...component} />;
      case 'simpleSlider':
        return <SimpleSlider key={i} {...component} />;
      case 'image':
        return <ImageSection key={i} {...component} />;
      case 'programs':
        return <Programs key={i} slug={slug} {...component} />;
      case 'casesShowcase':
        return <CasesShowcase key={i} {...component} />;
      case 'partnersSlider':
        return <PartnersSlider key={i} {...component} />;
      case 'articleShowcase':
        return (
          <ArticleShowcase
            className="section-bg section-bg--grey"
            key={i}
            articles={component.articles.map((article: any) => article.article)}
            title={component.title}
            desc={component.desc}
            cta={component.cta}
          />
        );
      case 'contact':
        return <Contact key={i} slug={slug} {...component} />;
      case 'quoteSlider':
        return <QuoteSlider key={i} {...component} />;
      case 'title':
        return <Title key={i} {...component} />;
      case 'result':
        return <Result key={i} {...component} slug={slug} />;
      case 'peopleShowcase':
        return <PeopleShowcase key={i} {...component} />;
      case 'markdown':
        return <Markdown key={i} {...component} />;
      case 'promo':
        return <Promo key={i} {...component} />;
      case 'faq':
        return <FAQ key={i} {...component} />;
      case 'gptCarousel':
        return <GPTCarousel key={i} {...component} slug={slug} />;
      default:
        return null;
    }
  });
}

export default PageBuilder;
